import apiEndPoints from "@/consts/apiEndPoints"
import api from "@/services/api"

export async function getEmptyOrderId() {
  try {
    const response = await api.get(apiEndPoints.orders.empty)
    return response.data.order_id
  } catch (error) {
    return null
  }
}

export async function removeEmptyOrders() {
  try {
    await api.delete(apiEndPoints.orders.empty)
  } catch (error) {
    console.log(error)
  }
}
